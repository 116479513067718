@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #1a0033, #000000);
  color: #ffffff;
  min-height: 100vh;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Add these new styles for inputs */
input[type="text"],
input[type="number"],
input[type="date"] {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #00ffff;
  background-color: rgba(0, 255, 255, 0.1);
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

/* Style for input placeholders */
input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Focus styles for inputs */
input:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

h1 {
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 10px rgba(255, 0, 255, 0.5);
  text-align: center;
}

p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #cccccc;
  text-align: center;
}

.btn {
  font-family: 'Poppins', sans-serif;
  display: inline-block;
  padding: 12px 24px;
  border: none;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

.btn-primary {
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  color: #ffffff;
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.5);
}

.btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 0 30px rgba(255, 0, 255, 0.7);
}

.btn-secondary {
  background: transparent;
  color: #ffffff;
  border: 2px solid #00ffff;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.3);
  margin: 0 10px;
}

.btn-secondary:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.5);
  transform: translateY(-3px);
}

footer {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 1rem 0;
  margin-top: 2rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.footer-copyright {
  margin: 0;
  line-height: 1;
}

.copyright {
  font-size: 0.8rem;
  color: #cccccc;
  text-align: center;
}

.disclaimer-button {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #555555; /* Darker grey outline */
  color: #cccccc;
  cursor: pointer;
  font: inherit;
  font-size: 0.9rem;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.disclaimer-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  border-color: #777777; /* Slightly lighter on hover for contrast */
}

.disclaimer-text {
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

@media (max-width: 768px) {
  .footer-main {
    flex-direction: column;
  }

  .footer-item {
    width: 100%;
    justify-content: center;
    margin: 0.5rem 0;
  }
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  padding: 4rem 0;
  text-align: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.about-me-button {
  margin-bottom: 3rem;
}

.project-buttons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-top: 2rem;
}

/* Color splash effect */
.color-splash {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  filter: blur(100px);
  opacity: 0.3;
  z-index: -1;
}

.splash-1 {
  top: 10%;
  left: 10%;
  background-color: #ff00ff;
}

.splash-2 {
  bottom: 10%;
  right: 10%;
  background-color: #00ffff;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }
  
  .btn {
    font-size: 1rem;
    padding: 10px 20px;
  }
  
  .project-buttons {
    flex-direction: column;
    align-items: center;
  }
  
  .btn-secondary {
    margin: 0.75rem 0;
  }
}

/* Add this new class for the stock input container */
.stock-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

/* Style for the remove button */
.remove-stock-btn {
  padding: 10px;
  background-color: #ff4136;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-stock-btn:hover {
  background-color: #ff1a1a;
}

/* Add these new styles for the FinanceProject3 component */
.finance-project-3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.finance-project-3 > div {
  width: 100%;
  max-width: 800px;
}

.finance-project-3 table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
}

.finance-project-3 th,
.finance-project-3 td {
  border: none;
}

.finance-project-3 th {
  background-color: #1f2937;
  font-weight: 600;
  color: #d1d5db;
  text-transform: uppercase;
}

.finance-project-3 tr:nth-child(even) {
  background-color: #374151;
}

.finance-project-3 tr:hover {
  background-color: #4b5563;
}

/* Override any conflicting global styles */
.finance-project-3 input[type="text"],
.finance-project-3 input[type="number"],
.finance-project-3 select {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.finance-project-3 input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.finance-project-3 .btn {
  color: #ffffff;
}

/* ... (rest of the styles remain unchanged) */

/* Update these styles to be more specific */
.finance-project-1 input[type="text"],
.finance-project-1 input[type="number"],
.finance-project-1 input[type="date"] {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #00ffff;
  background-color: rgba(0, 255, 255, 0.1);
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

/* Update placeholder styles to be more specific */
.finance-project-1 input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Update focus styles to be more specific */
.finance-project-1 input:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

/* Add these new styles for the table in FinanceProject1 */
.finance-project-1 table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.finance-project-1 th,
.finance-project-1 td {
  padding: 0.75rem 1rem;
  text-align: left;
}

.finance-project-1 th {
  background-color: #1f2937;
  font-weight: 600;
  color: #d1d5db;
  text-transform: uppercase;
}

.finance-project-1 tr:nth-child(even) {
  background-color: #374151;
}

.finance-project-1 tr:hover {
  background-color: #4b5563;
}

/* ... (rest of the styles remain unchanged) */

/* Add these new styles for the FinanceProject2 component */
.finance-project-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.finance-project-2 > div {
  width: 100%;
  max-width: 800px;
}

.finance-project-2 table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.finance-project-2 th,
.finance-project-2 td {
  border: 1px solid #4a5568;
  padding: 0.75rem 1rem;
  text-align: left;
}

.finance-project-2 th {
  background-color: #2d3748;
  font-weight: 600;
  color: #e2e8f0;
  text-transform: uppercase;
}

.finance-project-2 tr:nth-child(even) {
  background-color: #2d3748;
}

.finance-project-2 tr:hover {
  background-color: #4a5568;
}

/* Override any conflicting global styles */
.finance-project-2 input[type="text"],
.finance-project-2 input[type="number"],
.finance-project-2 select {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.finance-project-2 input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.finance-project-2 .btn {
  color: #ffffff;
}

/* ... (rest of the styles remain unchanged) */